export const serviceData = [
    {
      id: 1,
      number: '01',
      title: 'Saas/Development',
      description: 'We specialize in crafting robust and scalable Software-as-a-Service (SaaS) solutions tailored to your unique business needs. Our expert development team delivers custom software applications that drive efficiency, enhance customer experiences, and boost your bottom line. ',
      url: '/services',
    },
    {
        id: 2,
        number: '02',
        title: 'Data Analysis',
        description: 'Unlock the hidden potential of your data with our comprehensive data analytics services. Our data experts transform raw information into actionable insights that drive informed decision-making.',
        url: '/services',
      },
      {
        id: 3,
        number: '03',
        title: 'ServiceNow',
        description: 'Streamline your IT operations and enhance service delivery with our ServiceNow expertise. We offer implementation, customization, and development services to optimize your ServiceNow platform.',
        url: '/services',
      },
      {
        id: 4,
        number: '04',
        title: 'AI-ML',
        description: 'AI/ML Embrace the power of artificial intelligence and machine learning with our cutting-edge solutions. We develop intelligent systems that automate tasks, make predictions, and uncover valuable insights from your data.',
        url: '/services',
      },
      {
        id: 5,
        number: '05',
        title: 'Cloud Based BI- Solutions',
        description: 'Access real-time insights and make data-driven decisions with our cloud-based business intelligence solutions. We deliver scalable and secure BI platforms that empower you to visualize complex data, identify trends, and measure performance. ',
        url: '/services',
      },
      {
        id: 6,
        number: '06',
        title: 'Design ',
        description: 'Design Elevate your brand and captivate your audience with our exceptional design services. Our creative team delivers visually stunning designs that align with your business objectives. From logo design and branding to user interface (UI) and user experience (UX) design, we create designs that leave a lasting impression. ',
        url: '/services',
      },
      {
        id: 7,
        number: '07',
        title: 'Mobile Application',
        description: 'Reach your customers on the go with our custom mobile app development services. We build engaging and user-friendly mobile apps that enhance your brand presence and drive customer engagement. Our expert developers create native, hybrid, or cross-platform apps tailored to your specific requirements.',
        url: '/services',
      },
      {
        id: 8,
        number: '08',
        title: 'infivent',
        description: 'Event Management Create unforgettable events with our comprehensive event management services. We handle every aspect of event planning and execution, from concept development to on-site management. Our experienced team ensures seamless event logistics, exceptional guest experiences, and successful outcomes.',
        url: 'https://www.infivent.io/',
        target: "_blank",
      },
]
