import React,{useEffect} from 'react'
import HeroSection from './herosection/HeroSection'
import Header from '../header/Header'
import Footer from '../Footer'
import ServiceComponent from './servicecomponent/ServiceComponent'

const MainServices = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
   <>
     <Header />
     <HeroSection />
     <ServiceComponent />
     {/* <Footer /> */}
   </>
  )
}

export default MainServices
