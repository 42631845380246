import React,{useEffect} from "react";
import ProcohatGroup from "../../../assets/images/ProcohatGroup.png";
import "./style/heroandteam.css";
import {FaLinkedin} from "react-icons/fa";
import WasuSir from "../../../assets/images/WasuSir-bw.png";
import Saloni from "../../../assets/images/SaloniMam-bw.png";
import HemantSir from "../../../assets/images/HemantSir-bw.png";
import RajeshSir from "../../../assets/images/RajeshSir-bw.png";
import AfzalSir from "../../../assets/images/AfzalSir-bw.png";
import PrateekSir from "../../../assets/images/Prateeksir-bw.png";
import AnshulSir from "../../../assets/images/AnshulSir-bw.png";
import Prathmesh from "../../../assets/images/Prathmesh-bw.png";
import AnandSir from '../../../assets/images/AnandSir-bw.png';
import NirbhaySir from '../../../assets/images/NirbhaySir-bw.png';
import ParamveerSir from '../../../assets/images/Paramveer-bw.png';
import AkshaySir from '../../../assets/images/AkshaySir-bw.png';
import HetalMam from '../../../assets/images/HetalMam-bw.png';
import Aos from "aos";

import "aos/dist/aos.css";
// import { Image } from "lucide-react";

const HeroAndTeam = () => {
  useEffect(()=>{

    Aos.init({
      duration: 2000,
      offset: 100,
    });
  },[]);
  return (
    <>
      <section className="aboutus-hero-section w-full h-auto mt-20 flex flex-col justify-center items-center">
        <div className="aboutus-hero-image w-full h-full relative">
          {/* <img src={ProcohatGroup} alt="Group Image" className="w-full h-full " /> */}
        </div>
      </section>
      <section className="w-full py-5 relative aboutus-teams">
      <div className="aboutus-teams-directors w-full flex flex-col justify-center items-center relative">
  <div className="bg-light-1"></div>
  <div className="aboutus-teams-directors-container w-full max-w-[1200px] py-10">
    <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-white text-center font-semibold mt-5 sm:mt-6 mb-6 md:mt-8 lg:mt-10" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
      Board of <span className="text-[#AB2EFC]">Directors</span>
    </h2>
    <div className="aboutus-teams-directors-content-row pt-10">
      <div className="aboutus-team-member relative" id="wasudev">
        <img src={WasuSir} alt="Wasudev Mishra" className="about-team-member-image"/>
        <div className="team-caption py-5 rounded-[17.28px]">
          <h3 className="text-white text-[17px] font-semibold">
            Mr. <span className="text-[#8952FD]">Wasudev</span> Mishra
          </h3>
          <p className="text-[13px] text-white font-normal mt-3">
            <span className="text-[16px]">Founder & President, ProCohat</span>
            
          </p>
          <div className="mt-2">
            <a href="https://www.linkedin.com/in/wasudev-mishra-618143a4/" target="_blank">
              <FaLinkedin className="icon" />
            </a>
          </div>
        </div>
      </div>

      <div className="aboutus-team-member relative" id="hemantsir">
        <img src={Saloni} alt="Saloni Baghwani" className="about-team-member-image"/>
        <div className="team-caption py-5 rounded-[17.28px]">
          <h3 className="text-white text-[17px] font-semibold">
            Ms. <span className="text-[#8952FD]">Saloni</span> Bagwani
          </h3>
          <p className="text-[13px] text-white font-normal mt-3">
            <span className="text-[16px]">Director, HR, ProCohat</span><br/>
            
          </p>
          <div className="mt-2">
            <a href="https://www.linkedin.com/in/saloni-bagwani/" target="_blank">
              <FaLinkedin className="icon" />
            </a>
          </div>
        </div>
      </div>

      <div className="aboutus-team-member relative" id="afzalsir">
        <img src={AfzalSir} alt="Afzal Sorathiya" className="about-team-member-image"/>
        <div className="team-caption py-5 rounded-[17.28px]">
          <h3 className="text-white text-[17px] font-semibold">
            Mr. <span className="text-[#8952FD]">Afzal </span> Sorathiya
          </h3>
          <p className="text-[13px] text-white font-normal mt-3">
            <span className="text-[16px]">Head IT, SMS Limited</span> 
            
          </p>
          <div className="mt-2">
            <a href="https://www.linkedin.com/in/afzal-sorathiya/" target="_blank">
              <FaLinkedin className="icon" />
            </a>
          </div>
        </div>
      </div>             
    </div>
  </div>
</div>

          
        <div className="aboutus-teams-directors w-full flex flex-col justify-center items-center relative"> 
          <div className="bg-light-1"></div>
          <div className="aboutus-teams-directors-container w-full max-w-[1200px] py-10">
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-white text-center font-semibold mt-5 sm:mt-6 mb-6 md:mt-8 lg:mt-10" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
              Leadership <span className="text-[#AB2EFC]">Team</span>
            </h2>
            <div className="aboutus-teams-directors-content-row pt-10">
            <div className="aboutus-team-member relative" id="prateek">
                <img src={PrateekSir} alt="Prateek Ghumare" className="about-team-member-image"/>
                <div className="team-caption  py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mr. <span className="text-[#8952FD]">Prateek</span> Ghumare
                  </h3>
                  <p className="text-[13px] text-white font-normal mt-3"><span className="text-[16px]">Chief Operating Officer, ProCohat</span></p>
                  <div className="mt-2">
                     <a href="" target="_blank">
                      <FaLinkedin className="icon" />
                         </a>
                 </div>
                </div>
              </div>
              <div className="aboutus-team-member relative" id="hetalmam">
                <img src={HetalMam} alt="Hetal Sampat" className="about-team-member-image"/>
                <div className="team-caption py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mrs. <span className="text-[#8952FD]">Hetal</span> Sampat
                  </h3>
                  <p className="text-[13px] text-white font-normal mt-3"><span className="text-[16px]">Group Chief Financial Officer, ProCohat</span></p>

                  <div className="mt-2">
                    <a href="https://www.linkedin.com/in/hetal-sampat-56a73b4/" target="_blank">
                      <FaLinkedin className="icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="aboutus-team-member relative" id="anshul">
                <img src={AnshulSir} alt="Anshul Nagpure" className="about-team-member-image"/>
                <div className="team-caption py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mr. <span className="text-[#8952FD]">Anshul</span> Nagpure
                  </h3>
                  <p className=" text-white font-normal mt-3"><span className="text-[16px]">Head of Business Development, ProCohat</span></p>
                  <div className="mt-2">
                     <a href="https://www.linkedin.com/in/anshul-nagpure070892/" target="_blank">
                      <FaLinkedin className="icon" />
                         </a>
                 </div>
                </div>
              </div>            
              
                 <div className="aboutus-team-member relative" id="prathmesh">
                <img src={Prathmesh} alt="Prathmesh Gokhe" className="about-team-member-image"/>
                <div className="team-caption  py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mr. <span className="text-[#8952FD]">Prathmesh </span> Gokhe
                  </h3>
                  <p className="text-white font-normal mt-3"><span className="text-[16px]">Head of product - infivent</span></p>
                  <div className="mt-2">
                     <a href="https://www.linkedin.com/in/prathmesh-gokhe/" target="_blank">
                      <FaLinkedin className="icon" />
                         </a>
                 </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>


        <div className="aboutus-teams-advisors w-full flex flex-col justify-center items-center relative py-10">
          <div className="bg-light-2"></div>
          <div className="aboutus-teams-advisors-container w-full max-w-[1200px] py-10">
          <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-white text-center font-semibold mt-5 sm:mt-6 mb-6 md:mt-8 lg:mt-10" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
              Our <span className="text-[#AB2EFC]">Mentors</span>
            </h2>
            <div className="aboutus-teams-advisors-content-row pt-10">
              <div className="aboutus-team-member relative" id="anandsir">
                <img src={AnandSir} alt="Anand Sancheti" className="about-team-member-image"/>
                <div className="team-caption py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mr. <span className="text-[#8952FD]">Anand</span> Sancheti</h3>
                  <p className="text-white font-normal mt-3"><span className="text-[16px]">Managing Director, SMS Limited</span> </p>
                  <div className="mt-2">
                    <a href="https://www.linkedin.com/in/anandsancheti/" target="_blank">
                      <FaLinkedin className="icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="aboutus-team-member relative" id="saloni">
                <img src={HemantSir} alt="Hemant Lodha" className="about-team-member-image"/>
                <div className="team-caption  py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    CA <span className="text-[#8952FD]">Hemant</span> Lodha
                  </h3>
                  <p className="text-white font-normal mt-3"><span className="text-[16px]">Director, SMS Envocare Limited</span></p>
                  <div className="mt-2">
                     <a href="https://www.linkedin.com/in/hemantclodha/" target="_blank">
                      <FaLinkedin className="icon" />
                         </a>
                 </div>
                </div>
              </div>
              <div className="aboutus-team-member relative" id="nirbhaysir">
                <img src={NirbhaySir} alt="Nirbhay Sancheti" className="about-team-member-image"/>
                <div className="team-caption py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mr. <span className="text-[#8952FD]">Nirbhay</span> Sancheti
                  </h3>
                  <p className="text-white font-normal mt-3"><span className="text-[16px]">Director, Mining, SMS Limited</span> </p>
                  <div className="mt-2">
                    <a href="https://www.linkedin.com/in/nirbhay-sancheti-700163" target="_blank">
                      <FaLinkedin className="icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="aboutus-team-member relative" id="paramveer">
                <img src={ParamveerSir} alt="Paramveer Sancheti" className="about-team-member-image"/>
                <div className="team-caption py-5  rounded-[17.28px] ">
                  <h3 className="text-white text-[17px]  whitespace-nowrap font-semibold">
                    Mr. <span className="text-[#8952FD] white-space: nowrap">Paramveer</span> Sancheti
                  </h3>
                  <p className="text-[13px] text-white  font-normal mt-3"><span className="text-[16px]">Director, Environment, SMS Limited</span> </p>
                  <div className="mt-2">
                    <a href="https://www.linkedin.com/in/paramveer-sancheti-a6107937" target="_blank">
                      <FaLinkedin className="icon text-[#0A66C2] text-xl" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="aboutus-team-member relative" id="akshaysir">
                <img src={AkshaySir} alt="Akshay Sancheti" className="about-team-member-image"/>
                <div className="team-caption py-5 rounded-[17.28px]">
                  <h3 className="text-white text-[17px] font-semibold">
                    Mr. <span className="text-[#8952FD]">Akshay</span> Sancheti
                  </h3>
                  <p className="text-[13px] text-white font-normal mt-3"><span className="text-[16px]">Director, Infrastructure, SMS Limited</span></p>
                  <div className="mt-2">
                    <a href="https://www.linkedin.com/company/procohat/" target="_blank">
                      <FaLinkedin className="icon" />
                    </a>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroAndTeam;
