import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import { useEffect } from "react";

const RefundPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <Header />
        <section className="text-white mt-20 container p-12 ml-auto mr-auto">
            <h1 className="text-3xl font-semibold mb-4">Cancellation & Refund Policy</h1>
            <p className="mb-4 text-[#bbbbbb]">Last updated July 03, 2024</p>
            <h2 className="text-2xl mb-4">REFUNDS</h2>
            <p className="mb-4 text-[#bbbbbb]">We are ProCohat Technologies Private Limited (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; &quot;our&quot;), a company registered in India at Plot No, 20, IT Park Rd, Parsodi, Nagpur, Maharashtra 440022.</p>
            <p className="mb-4 text-[#bbbbbb]">We operate the website <Link className="underline text-white" href={'https://www.procohat.com/'}>https://www.procohat.com</Link> (the &quot;Site&quot;), the mobile application Infivent (the &quot;App&quot;) <Link className="underline text-white" href={'https://www.infivent.io/'}>https://www.infivent.io</Link> , as well as any other related products and services that refer or link to these legal terms (the &quot;Legal Terms&quot;) (collectively, the &quot;Services&quot;).</p>
            <p className="mb-4 text-[#bbbbbb]">You can contact us by phone at (+91)7385650385, email at admin@procohat.com, or by mail to 20, IT Park Rd, Gayatri Nagar, Pratap Nagar, Nagpur, Maharashtra 440022, India, Nagpur, Maharashtra 440022, India. <br /><br />

                

              </p>
            <h2 className="text-2xl mb-4">REFUNDS</h2>
            <p className="mb-4 text-[#bbbbbb]">All sales are final and no refund will be issued.    </p>
            <h2 className="text-2xl mb-4">QUESTIONS</h2>
            <p className="mb-4 text-[#bbbbbb]">If you have any concerning our return policy, please contact us at: <br /><br />
                <b>
                    ProCohat Technologies Private Limited <br />
                    20, IT Park Rd, Gayatri Nagar, Pratap Nagar, Nagpur, Maharashtra 440022, India<br />
                    Nagpur, Maharashtra 440022<br />
                    India<br />
                    Phone: (+91)7385650385<br />
                    info@procohat.com</b></p>

        </section>
        </>
    );
}

export default RefundPolicy;