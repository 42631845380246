import React from 'react'
import './style/clientNew.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ClinetsHome = () => {
    const setting1 ={
        dots: false,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1500,
                settings:{
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0
                }
            },
            {  
                breakpoint: 1080,
                settings:{
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0
                }   
            },
            {
                breakpoint: 980,
                settings:{
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0

                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
            {
                breakpoint: 520,
                settings:{
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
            {
                breakpoint: 380,
                settings:{
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
        ]
    };
    const setting2 ={
        dots: false,
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: "linear",
        rtl: true,
        responsive: [
            {
                breakpoint: 1400,
                settings:{
                    slidesToShow: 8,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0
                }
            },
            {
                breakpoint: 1080,
                settings:{
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
            {
                breakpoint: 980,
                settings:{
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
            {
                breakpoint: 520,
                settings:{
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
            {
                breakpoint: 380,
                settings:{
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 2000,
                    autoplay: true,
                    autoplaySpeed: 0,
                }
            },
        ]
    };
  return (
    
    <section className='client-section-new'>
    <div className='client-section-container-new'>
       <div className='client-new-title'>
           <h1>Our <span className='text-[#A74AFF]'>Clientele</span></h1>
       </div>
       <div className="client-slider-1-new"> 
       <Slider {...setting1}>
           <div><img src='/Clients/golden-leaf.svg' alt="Golden Leaf" /></div>
           <div><img src='/Clients/Rcnv.svg' alt="RCNV" /></div>
           <div><img src='/Clients/RotaryClub-nagpur.svg' alt="Rotary Club of Nagpur" /></div>
           <div><img src='/Clients/InterviewPrep.svg' alt="InterviewPrep" /></div>
           <div><img src='/Clients/RYE.svg' alt="Rotart Youth Exchange" /></div>
           <div><img src='/Clients/Swacch-Association.svg' alt="Swacch Association" /></div>
           <div><img src='/Clients/Rotract3030.svg' alt="Rotract District 3030" /></div>
           <div><img src='/Clients/NMC.svg' alt="NMC" /></div>
           <div><img src='/Clients/Iit-kanpur.svg' alt="IIT Kanpur"/></div>
           <div><img src='/Clients/FFIRIST.svg' alt="FFIRIST" /></div>
           <div><img src='/Clients/Boiler-India.svg' alt="Boiler India" /></div>
                    
       </Slider>  
       </div>
       <div className="client-slider-2-new"> 
       <Slider {...setting2}>
           <div><img src="/Clients/SMS-envocare.svg" alt="SMS Envocare" /></div>
           <div><img src="/Clients/Smsl.svg" alt="SMSL" /></div>
           <div><img src="/Clients/Ignite-Production.svg" alt="Ignite Production" /></div>
           <div><img src="/Clients/Isckon.svg" alt="Iskcon" /></div>
           <div><img src="/Clients/Impulse.svg" alt="Impulse" /></div>
           <div><img src="/Clients/Iskcon-Ashraya.svg" alt="Iskcon Ashraya" /></div>
           <div><img src="/Clients/Tata-International.svg" alt="Tata International" /></div>
           <div><img src="/Clients/FS.svg" alt="FS" /></div>
           <div><img src="/Clients/Tanwish.svg" alt="Tanwish" /></div>
           <div><img src="/Clients/Ey.svg" alt="EY" /></div>
           <div><img src="/Clients/Pioneer.svg" alt="Pioneer" /></div>
           
       </Slider>  
       </div>
    </div>
</section>
  )
}

export default ClinetsHome
