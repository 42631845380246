import React,{useEffect} from 'react'
import HeroAndTeam from './heroandteam/HeroAndTeam'
import Header from '../header/Header'
import AboutInfo from './aboutinfo/AboutInfo';
import VisionMission from './visionmission/VisionMission';

const MainAbout = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <HeroAndTeam />
      <AboutInfo />
      <VisionMission />
    </>
  )
}

export default MainAbout
