import React, {useEffect} from 'react'
import HeroSection from './hero/HeroSection'
import Header from '../header/Header'
import About from './about/About'
// import WebApp from './webapp/WebApp'
import Services from './services/Services'
import ClinetsHome from './clients/ClinetsHome'

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header/>
       <HeroSection />
       <About/>
       {/* <WebApp /> */}
       <Services />
       <ClinetsHome />
    </>
  )
}

export default Main;