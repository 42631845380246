import React from 'react';
// import { Link } from 'react-router-dom';
import "./style/sliderCase.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import 'swiper/css/effect-coverflow';
import  { EffectCoverflow } from 'swiper/modules';
import SwiperCore from "swiper";
import CaseStudies1 from '../../assets/images/Case-studies-1.png';
import CaseStudies2 from '../../assets/images/Case-studies-2.png';
import CaseStudies3 from '../../assets/images/Case-studies-3.png';

// import SwiperCore from 'swiper';

SwiperCore.use([EffectCoverflow]);
// SwiperCore.use([EffectCoverflow]);
const SliderCase = ({
  onReadMoreClick1, onReadMoreClick2, onReadMoreClick3 
}) => {
  
  return (
   <div className='main-case-swiper-section'>
    <div className='main-case-swiper-container'>
      <Swiper
      effect="coverflow"
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      spaceBetween={0}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 2.5,
        slideShadows:true,   
      }}
      initialSlide={1}
      className='case-swiper-container' 
      >
      <SwiperSlide>
         <div className="case-content-container">
           <img src={CaseStudies3} alt="CaseStudies3" />
           <div className="case-content">
            <h1>Impulse case studies </h1>
            <p>IDC case study ProCohat excels in the different ventures including development of OTT platform website, our client IDC came up with a unique requirement to</p>
            <button className='btn btn-case' onClick={onReadMoreClick3}>Read more <i class="bi bi-chevron-right"></i></button>
           </div>
         </div>
      </SwiperSlide>  
      <SwiperSlide> <div className="case-content-container">
           <img src={CaseStudies1} alt="CaseStudies3" />
           <div className="case-content">
            <h1>How Procohat is empowering dancer community through its OTT platforms </h1>
            <p>IDC case study ProCohat excels in the different ventures including development of OTT platform website, our client IDC came up with a unique requirement to</p>
          <button className='btn btn-case' onClick={onReadMoreClick1}>Read more <i class="bi bi-chevron-right"></i></button>
           </div>
         </div></SwiperSlide>  
      <SwiperSlide> <div className="case-content-container">
           <img src={CaseStudies2} alt="CaseStudies3" />
           <div className="case-content">
            <h1>Generalising Groceries and Items through our E-Commerce Solutions</h1>
            <p>IDC case study ProCohat excels in the different ventures including development of OTT platform website, our client IDC came up with a unique requirement to</p>
           <button className='btn btn-case' onClick={onReadMoreClick2}>Read more <i class="bi bi-chevron-right"></i></button>
           </div>
         </div></SwiperSlide>  
      </Swiper>
    </div>
   </div>
  )
}

export default SliderCase

