import React, { useEffect, useState, useRef} from "react";
import SliderCase from "../components/slider-case/SliderCase";
import "../styles/caseStudies.css";
import CaseStudies1 from "../assets/images/Case-studies-1.png";
import CaseStudies2 from "../assets/images/Case-studies-2-1.png";
import CaseStudies3 from "../assets/images/Case-studies-3-1.png";
import Aos from "aos";
import "aos/dist/aos.css";
import SlideBtn from "../components/slidebtn/SlideBtn";
import Header from "../components/header/Header";

const CaseStudies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
   useEffect(()=>{
    Aos.init({
      duration: 2000,
      offset: 100,
    });
   },[]);
   const [showContent1, setShowContent1] = useState(false);
   const [showContent2, setShowContent2] = useState(false);
   const [showContent3, setShowContent3] = useState(false);

   const [shouldScroll1, setShouldScroll1] = useState(false);
   const [shouldScroll2, setShouldScroll2] = useState(false);
   const [shouldScroll3, setShouldScroll3] = useState(false);

   useEffect(() => {
    if (shouldScroll1 && caseStudy1Ref.current) {
      caseStudy1Ref.current.scrollIntoView({ behavior: "smooth" });
      // Reset shouldScroll to false to prevent repeated scrolling
      setShouldScroll1(false);
    }
    if (shouldScroll2 && caseStudy2Ref.current) {
      caseStudy2Ref.current.scrollIntoView({ behavior: "smooth" });
      // Reset shouldScroll to false to prevent repeated scrolling
      setShouldScroll2(false);
    }
    if (shouldScroll3 && caseStudy3Ref.current) {
      caseStudy3Ref.current.scrollIntoView({ behavior: "smooth" });
      // Reset shouldScroll to false to prevent repeated scrolling
      setShouldScroll3(false);
    }
   },[shouldScroll1,shouldScroll2,shouldScroll3]);

   const caseStudy1Ref = useRef(null);
  const caseStudy2Ref = useRef(null);
  const caseStudy3Ref = useRef(null);
    // Callback functions to toggle the visibility of each case study content
  const handleReadMoreClick1 = () => {
    // console.log("handleReadMoreClick1 called");
    setShowContent1(!showContent1);
    setShowContent2(false);
    setShowContent3(false);
    // if (!showContent1 && caseStudy1Ref.current) {
    //   // console.log("Scrolling to caseStudy1Ref");
    //   caseStudy1Ref.current.scrollIntoView({ behavior: "smooth" });
    // }
    if (!showContent1) {
      setShouldScroll1(true);
    }
  };

  const handleReadMoreClick2 = () => {
    setShowContent2(!showContent2);
    setShowContent1(false);
    setShowContent3(false);
    if (!showContent2) {
      setShouldScroll2(true);
    }
  };

  const handleReadMoreClick3 = () => {
    setShowContent3(!showContent3);
    setShowContent1(false);
    setShowContent2(false);
    if (!showContent3) {
      setShouldScroll3(true);
    }
  };
  return (
    <> 
    <Header />
      <section className="case-studies-section">
        <div className="case-hero-content-container">
          <div className="case-hero-content">
            <h1 data-aos="zoom-in-up" data-aos-easing="ease">Case Studies</h1>
            <p data-aos="zoom-in-up" data-aos-easing="ease" data-aos-delay="700">
              Interact with our products like banking APIs to enable
              <br /> account creation, linking, payouts, and management.
            </p>
          </div>
        </div>
        <SliderCase 
         onReadMoreClick1={handleReadMoreClick1}
         onReadMoreClick2={handleReadMoreClick2}
         onReadMoreClick3={handleReadMoreClick3}
        />
        <div className="sunlight-brightnes-case-1"></div>
        <div className={`case-study-1 case-study ${showContent1 ? "show" : "hide"}`} id="caseStudy-1" ref={caseStudy1Ref}>
          <div className="case-study-container">
            <div className="case-study-image">
              <div className="case-study-image-box">
                <img src={CaseStudies1} alt="...." />
              </div>
            </div>
            <div className="case-study-content">
              <h3 data-aos="fade-left" data-aos-easing="ease">IDC case study</h3>
              <p data-aos="fade-left" data-aos-easing="ease" data-aos-delay="500">
                ProCohat excels in the different ventures including development
                of OTT platform website, our client IDC came up with a unique
                requirement to develop an OTT platform, based on dance community
                and choreography. We used WordPress to develop an IDC OTT
                platform website, AngularJS as a front-end and Node JS as a
                backend technology.
              </p>
              <h5 data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1000">Key Challenges–</h5>
              <p data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1500">
                These days, everything has gone online, entertainment and
                learning is no excuse.IDC is looking for an exclusive website
                for its subscription based OTT platform. Platform is unique of
                its kind , which is not purely an entertainment base, moreover,
                IDC required a platform for dance enthusiasts, who are seeking
                to learn various dance forms from the platform, actually a
                website required to be specific with its various dance forms and
                choreographers, master art Gurus in classical dance.
                User-friendly and fastrack website with an attractive multiple
                layout is required to build an OTT platform, which is also the
                least requirement of the viewers, who are seeking to learn an
                art form from the OTT platform.
              </p>
              <h5 data-aos="fade-left" data-aos-easing="ease" data-aos-delay="2000">Solutions</h5>
              <p data-aos="fade-left" data-aos-easing="ease" data-aos-delay="2500">
                We developed an IDC platform based on dance and choreography by
                Using WordPress content management system.Additionally we
                implemented AngularJS technology for simple, user-friendly and
                well organised functionality of the website.Certainly, the
                website looks very informative and alluring with its attractive
                drop down layout and linked web pages containing list of various
                videos of dancing artform linked to specific choreographer.
              </p>
            </div>
          </div>
        </div>
        <div className={`case-study-2 case-study ${showContent2 ? "show" : "hide"}`} id="caseStudy-2" ref={caseStudy2Ref}>
          <div className="case-study-container">
            <div className="case-study-content">
              <h3 data-aos="fade-right" data-aos-easing="ease">
                Generalizing Groceries and Items through our E-Commerce
                Solutions
              </h3>
              <p data-aos="fade-right" data-aos-easing="ease" data-aos-delay="500">
                Our primary goal is to provide an innovative and effective
                solution to our clients. ProCohat developed a dynamic E-commerce
                website for our client Lado. We designed a perfectly articulated
                and attractive E-commerce platform for Lado using HTML, PHP,
                Bootstrap, and Javascript technology.
              </p>
              <h5 data-aos="fade-right" data-aos-easing="ease" data-aos-delay="1000">Key Challenges–</h5>
              <p data-aos="fade-right" data-aos-easing="ease" data-aos-delay="1500">
                Lado is selling various grocery products online, in a highly
                competitive market of online grocery products, a recent survey
                shows only 3% of customers actually buy products from online
                grocery shopping sites. Lado wants to sell some automobile
                products like Automobile engine oil, keeping in mind
                motor-vehicle enthusiasts and their requirements. Requirement of
                lado is to build a user-friendly, simple and fast-track site
                which is appealing for customers. Secondly, customers should
                stick like glue on the platform, which certainly helps the Lado
                in sales and necessary boost to the product.
              </p>
              <h5 data-aos="fade-right" data-aos-easing="ease" data-aos-delay="2000">Solutions</h5>
              <p data-aos="fade-right" data-aos-easing="ease" data-aos-delay="2500">
                We developed elegant,classic and user-friendly website by using
                Bootstrap,HTML ,PHP and Javascript technology. By using PHP and
                Javascript we added charismatic attractive layout and full-width
                sliders, which give a specific description of various products
                in a single layout of the Grocery chart. Moreover, it gives a
                heavenly look to the website and eye-catching layouts makes it a
                perfect place to shop for customers. We have made a fully
                responsive, clean , and well-articulated webpage,
                furthermore,the functionality of the web-page is quite appealing
                for motorheads.
              </p>
            </div>
            <div className="case-study-image">
              <div className="case-study-image-box">
                <img src={CaseStudies2} alt="...." />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sunlight-brightnes-case-2"></div> */}
        <div className={`case-study-3 case-study ${showContent3 ? "show" : "hide"}`} id="caseStudy-3" ref={caseStudy3Ref}>
          <div className="case-study-container">
            <div className="case-study-image">
              <div className="case-study-image-box">
                <img src={CaseStudies3} alt="...." />
              </div>
            </div>
            <div className="case-study-content">
              <h3 data-aos="fade-left" data-aos-easing="ease">Impulse case study</h3>
              <p data-aos="fade-left" data-aos-easing="ease" data-aos-delay="500" >
                ProCohat ideates the solutions that solve the global level
                problem with sustainable outcomes and helps the customer to
                build an impressively designed website consisting of a brand,
                image with multiple templates, and mesmerizing page layouts.
                Secondly, we assisted the company with internal linkings and
                plugins that help to integrate with various social media
                platforms.
              </p>
              <h5 data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1000">Key Challenges–</h5>
              <p data-aos="fade-left" data-aos-easing="ease" data-aos-delay="1500">
                The company required a new stylish and attractive website to
                sell various apple products online. Nowadays, there is a trend
                to go online and buy online. Customers are looking to purchase
                products online. Additionally, online business platforms are
                constantly aware of keeping their product and services in front
                of the eyes of customers. Our client was looking for a
                user-friendly and stunningly beautiful website to sell their
                various Apple products with cost-effective shipping options.
                Moreover, it enables the company to sell its products online
                with cost-effective shipping options.
              </p>
              <h5 data-aos="fade-left" data-aos-easing="ease" data-aos-delay="2000">Solutions</h5>
              <p data-aos="fade-left" data-aos-easing="ease" data-aos-delay="2500">
                We used WooCommerce to build an effective and functional online
                shopfront website of various Apple products and other services,
                which includes the customization of the specific requirements of
                clients in the product. Furthermore, by using WooCommerce,
                products are managed with ease and added to the website. We
                created a website using WordPress CMS, which is user-friendly to
                build a website in a quick session of time, and it helps to
                update things without any hassle and tassel. We used the Astra
                theme, which is one of the best themes to build a commercial
                website. Secondly, we added various infographics images and
                synchronized the inventory in a drop-down layout, which improves
                the capability to display several products, their information,
                and reviews of products with relative ease. Moreover, Astra
                theme’s plugins help to interlink the website with several.
              </p>
            </div>
          </div>
        </div>
      </section>
      <SlideBtn />
    </>
  );
};

export default CaseStudies;
