import React,{useEffect} from 'react';
import './style/visionmission.css';
import Vision from '../../../assets/images/vision.png';
import Mission from '../../../assets/images/mission.png';
import Aos from "aos";
import "aos/dist/aos.css";;




const VisionMission = () => {
    useEffect(()=>{

        Aos.init({
          duration: 2000,
          offset: 100,
        });
      },[]);
  return (
    <>
      <section className="aboutus-vision-new">
        <div className="aboutus-vision-new-container">
          <div className="aboutus-vision-new-image">
            <img src={Vision} alt="Vision" />
          </div>
          <div className="aboutus-vision-new-content">
            <div className="aboutus-vision-new-title">
              <h3 data-aos="zoom-in-up" data-aos-easing="ease">
                Vision
              </h3>
            </div>
            
            <div
              className="aboutus-vision-new-text"
              data-aos="fade-left"
              data-aos-easing="ease"
              data-aos-delay="500"
            >
              <div className="aboutus-vision-new-text-paragraph">
                <p
                  data-aos="zoom-in"
                  data-aos-easing="ease"
                  data-aos-delay="700"
                >
                  "Shaping a digital future where collaboration among
                  corporates, industrialists, and changemakers thrives on
                  tailored solutions, accelerated strategies, and robust
                  encryptions."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutus-mission-new">
        <div className="aboutus-mission-new-container">
          <div className="aboutus-mission-new-content">
            <div className="aboutus-mission-new-title">
              <h3 data-aos="zoom-in-up" data-aos-easing="ease">
                Mission
              </h3>
            </div>
            <div
              className="aboutus-mission-new-text"
              data-aos="fade-right"
              data-aos-easing="ease"
              data-aos-delay="500"
            >
              <div className="aboutus-mission-new-text-paragraph">
                <p
                  data-aos="zoom-in"
                  data-aos-easing="ease"
                  data-aos-delay="700"
                >
                  To build a value chain of corporates, industrialists, and
                  changemakers by delivering digital business acceleration,
                  customized tech solutions, and secured encryptions.
                </p>
              </div>
            </div>
          </div>
          <div className="aboutus-mission-new-image">
            <img src={Mission} alt="Mission" />
          </div>
        </div>
      </section>
    </>
  )
}

export default VisionMission
