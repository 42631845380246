// import logo from './logo.svg';
// import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Infivent from "./pages/Infivent";
import CaseStudies from "./pages/CaseStudies";
import About from './pages/About';
import Header from "./components/header/Header";
import Footer from "./components/Footer"
import Contact from './pages/Contact';
import Preloader from './components/preloader/Preloader';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CoockiePolicy from './pages/CoockiePolicy';
import NewHome from './pages/NewHome';
import NewAbout from './pages/NewAbout';
// import NewHomePage from './pages/NewHomePage';
// import Industries from './pages/Industries';
import Landingpage from './components/landingpage/landingpage';
import NewServices from './pages/NewServices';
import TermsAndCondition from './pages/TearmsAndCondition';
import RefundPolicy from './pages/RefundPolicy';


function App() {
  // state variable for preloader function
  // const [isLoading, setIsLoading] = useState(true);

  // Loader Create Fake Sync data
  // useEffect(() => {
  //   const loaderFetch = () => {
  //     setTimeout(()=>{
  //       setIsLoading(false);
  //     }, 4000);
  //   }
  //   loaderFetch();
  // },[]);
 
  // return isLoading ? ( <Preloader />) : (
  return(
    <BrowserRouter>
       <Routes>
       {/* <Route path="*" element={<Landingpage />} />  */}
        <Route path="/" element={ <NewHome /> } />
        {/* <Route path="/" element={ <Home />} /> */}
        <Route path="/services" element={ <NewServices />} />
        <Route path="/infivent" element={ <Infivent />} />
        <Route path="/case-studies" element={ <CaseStudies /> } />
        <Route path="/about" element={ <NewAbout /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="/privacy" element={ <PrivacyPolicy /> } />
        <Route path="/cookie" element={ <CoockiePolicy /> } />
        <Route path="/termsandcondition" element={ <TermsAndCondition /> } />
        <Route path="/refund-policy" element={ <RefundPolicy /> } />
        {/* <Route path="/newservice" element={ <NewServices /> } /> */}
        {/* <Route path="/industries" element={ <Industries/> } /> */}
        {/* <Route path="/aboutnew" element={ <NewAbout /> } /> */}
       </Routes>
      <Footer /> 
    </BrowserRouter>
  );
}

export default App;
