import React, {useEffect} from 'react'
import Privacy from '../components/privacy/Privacy'
import SlideBtn from '../components/slidebtn/SlideBtn';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Privacy />
     <SlideBtn />
    </>
  )
}

export default PrivacyPolicy
