import React, { Component } from "react";
import { FaAnglesUp } from "react-icons/fa6";
import "./style/SlideBtn.css";

class SlideBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      scrollPercent: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scrollY = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const documentVisibleHeight = document.documentElement.clientHeight;
    const scrollProgress = scrollY / (documentHeight - documentVisibleHeight);

    if (window.scrollY > 100) {
      this.setState({ isVisible: true, scrollProgress });
    } else {
      this.setState({ isVisible: false, scrollProgress:0 });
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to 'auto' for an instant scroll
    });
  };

  render() {
    const { isVisible, scrollProgress } = this.state;

    return (
      <div className={`scroll-button ${isVisible ? "show" : "hide"}`}>
        <div className="progrss-bar" style={{ background: `conic-gradient(#A74AFF ${scrollProgress * 100}%, #363431 ${scrollProgress * 0}% ` }}>
          <button
            onClick={this.scrollToTop}
            title="Go to top"
            className="scroll-up-button"
          >
            <FaAnglesUp />
          </button>
        </div>
      </div>
    );
  }
}

export default SlideBtn;
