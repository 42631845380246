import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "../../assets/logo/logo-white.svg";
import "./style/header.css";
import { motion } from "framer-motion";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { MdKeyboardArrowDown,MdKeyboardArrowUp } from "react-icons/md";
// import IndustriesDropdown from "../dropdown/IndustriesDropdown";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);
  const [mobileResponsive, setMobileResponsive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [industriesDropdownOpen, setIndustriesDropdownOpen] = useState(false);


  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Change the background color when scrolling down
        setScrolling(true);
      } else {
        // Reset to the initial background color when scrolling up
        setScrolling(false);
      }
    };

    // Attach the event listener to the window's scroll event
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logoAnimation = {
    initial: { x: -100, opacity: 0 },
    animate: {
      x: 0,
      opacity: 1,
      transition: { duration: 1.8, ease: "linear" },
    }, // Adjust duration as needed
    exit: { x: -100, opacity: 0 },
  };

  const isNavLinkActive = (path) => {
    return location.pathname === path;
  };



  const iconStyle = {
    transition: "transform 0.3s ease",
    transform: mobileResponsive ? "rotate(90deg)" : "rotate(0deg)",
  };
  const navbarStyle = {
    transition: "linear all 0.5s",
    height: mobileResponsive ? "85vh" : "0",
    opacity: mobileResponsive ? "1" : "0",

  }
  

  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevents triggering closeMobileNav
    setDropdownOpen(!dropdownOpen);
  };
  //  const toggleIndustriesDropdown = (e) => {
  //   e.stopPropagation();
  //   setIndustriesDropdownOpen(!industriesDropdownOpen);
  // };

  const toggleMobileNav = () => {
    setMobileResponsive(!mobileResponsive);
  };
  const closeMobileNav = () => {
    setMobileResponsive(false);
  };



  return (
    <header className={`header ${scrolling ? "scrolling" : ""}`}>
      <div className="header-container">
        <NavLink to="/" onClick={closeMobileNav}>
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={logoAnimation}
          >
            <img src={Logo} alt="logo" className="logo"  style={{ width: '200px' }}/>    
          </motion.div>
        </NavLink>
        <nav>
          <div className="navbar">
            <ul className="navbar-list">
              <li
                // style={{ "--i": 1 }}
                className={isNavLinkActive("/services") ? "active" : ""}
              >
                <NavLink to="/services">Services</NavLink>
              </li>
              {/* <li  className={isNavLinkActive("/industries") ? "active" : "list-items"}>
               <IndustriesDropdown/>
              </li> */}
              <li
                //  style={{ "--i": 2 }}               
              >
                <div className="dropdown-toggle flex items-center gap-1 transition-all ease-in text-[20px]" onClick={toggleDropdown}>
                  Solutions {dropdownOpen ? <MdKeyboardArrowUp className="text-[px]"/> : <MdKeyboardArrowDown className="text-[30px]"/>  }
                </div>
                {dropdownOpen && (
                  <ul className="dropdown-menu mt-5 p-3 bg-slate-800">
                    <li className={isNavLinkActive("/infivent") ? "active" : ""}>
                      <NavLink to="/infivent">Infivent</NavLink>
                    </li>
                  </ul>
                )}
              </li>


              {/* <li
                style={{ "--i": 3 }}
                className={isNavLinkActive("/case-studies") ? "active" : ""}
              >
                <NavLink to="/case-studies">Case studies</NavLink>
              </li> */}
              <li
                // style={{ "--i": 3 }}
                className={isNavLinkActive("/about") ? "active" : ""}
              >
                <NavLink to="/about">About</NavLink>
              </li>
              <li
                // style={{ "--i": 4}}
                className={isNavLinkActive("/contact") ? "active" : ""}
              >
                <NavLink to="/contact">Get in touch</NavLink>
              </li>
            </ul>
          </div>
          <div className="hamburger">
            <button className="mobile-hamburger" onClick={toggleMobileNav}>
              <div style={iconStyle}>
                {mobileResponsive ? <AiOutlineClose /> : <AiOutlineMenu />}
              </div>
            </button>
          </div>
          <div className={`mobile-navbar ${mobileResponsive ? "open" : ""}`} onClick={closeMobileNav} style={navbarStyle}>
            <ul className="navbar-list">
              <li
                // style={{ "--i": 1 }}
                className={isNavLinkActive("/services") ? "active" : ""}
              >
                <NavLink to="/services" onClick={closeMobileNav}>Services</NavLink>
              </li>
              <li
                // style={{ "--i": 2 }}
                className={isNavLinkActive("/infivent") ? "active" : ""}
              >
                <div className="dropdown-toggle flex items-center gap-1" onClick={toggleDropdown}>
                  Solutions {dropdownOpen ? <MdKeyboardArrowUp className="text-[25px]"/> : <MdKeyboardArrowDown className="text-[25px]"/>  }
                </div>
                {dropdownOpen && (
                  <ul className="dropdown-menu mt-5 p-3">
                    <li>
                      <NavLink to="/infivent" onClick={closeMobileNav}>Infivent</NavLink>
                    </li>
                  </ul>
                )}
              </li>
              {/*               <li
                style={{ "--i": 3 }}
                className={isNavLinkActive("/case-studies") ? "active" : ""}
              >
                <NavLink to="/case-studies">Case studies</NavLink>
              </li> */}
              <li
                // style={{ "--i": 4 }}
                className={isNavLinkActive("/about") ? "active" : ""}
              >
                <NavLink to="/about" onClick={closeMobileNav}>About</NavLink>
              </li>
              <li
                // style={{ "--i": 5 }}
                className={isNavLinkActive("/contact") ? "active" : ""}
              >
                <NavLink to="/contact" onClick={closeMobileNav}>Get in touch</NavLink>
              </li>
            </ul>
          </div>
        </nav>
        {/* <button className="btn btn-touch"><NavLink to="/contact">Get in touch</NavLink></button> */}
      </div>

    </header>
  );
};

export default Header;
