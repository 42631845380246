import React from 'react';
import FooterLogo from '../../assets/images/logoproco.png';
import './style/landingpage.css';
import maintimg from '../../assets/images/maintimg.png';
import mailvector from '../../assets/images/vectormail.png';
import addvector from '../../assets/images/vectoradd.png';

function Landingpage() {
  return (
    <div>
        <img src={FooterLogo} className='logoprocohat' alt='logo'></img>
        <div className='paradiv'><span className='purplepara'>We’re currently making some updates to improve our website. </span><span>Sorry for the inconvenience. Please check back soon!</span></div>
        <p className='thankyou'>Thank you for your patience.</p>
        <div className="contact">
        <div className='infotype'>
        <div className="contactinfo">
                <p style={{fontSize:"30px" , fontWeight:"500"}}>Contact us</p>
                <p style={{fontSize:"20px" , fontWeight:"400"}}>We' love to hear from you , Our friendly team is always here to chat .</p>
                <div className="types"><label style={{fontSize:"19px" , color: "#00000080"}}>Name</label><br></br><br></br>
                <input type='text' style={{border: "none", borderBottom: "1px solid black", outline: "none", width: "400px"}}></input></div>
                <div className="types"><label style={{fontSize:"19px" , color: "#00000080"}}>Email</label><br></br><br></br>
                <input type='email' style={{border: "none", borderBottom: "1px solid black", outline: "none", width: "400px"}}></input></div>
                <div className="types"><label style={{fontSize:"19px" , color: "#00000080"}}>Message</label><br></br><br></br>
                <input type='text' style={{border: "none", borderBottom: "1px solid black", outline: "none", width: "400px"}}></input></div>
                <button>Submit</button>
        </div>
            <img src={maintimg} className='maintenimg' alt='contimg'></img> 
        </div>
        <div className='landfooter'>
            <div className="mail"><img src={mailvector} alt='mail'></img><span>info@procohat.com</span></div>
            <div className="add"><img src={addvector} alt='add'></img><span>Plot No, 20, IT Park Rd, Parsodi,Nagpur,<br></br> Maharashtra 440022</span></div>

        </div>
    </div>
    </div>
  )
}

export default Landingpage
