import React from 'react';
import MainAbout from '../components/aboutus/MainAbout';
import SlideBtn from '../components/slidebtn/SlideBtn';

const NewAbout = () => {
  return (
   <>
     <MainAbout />
     <SlideBtn />
   </>
  )
}

export default NewAbout
