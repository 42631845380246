import React, {useEffect} from 'react';
import './style/heroSection.css';
import { Link } from 'react-router-dom';
import ColorLine from '../../../assets/images/arrow-line.png'
import Triangle from '../../../assets/images/triangle-1.svg'
import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      useEffect(() => {
        Aos.init({
          duration: 2000,
          offset: 100,
        });
      }, []);
  return (
    <section className="new-service-main">
        <div className="new-arrow-line">
          <img
            src={ColorLine}
            alt=""
            data-aos="slide-left"
            data-aos-easing="ease"
          />
        </div>
        <div className="new-container">
          <div className="new-service-content">
            <h1
              className="new-service-heading"
              data-aos="zoom-out"
              data-aos-easing="ease"
              data-aos-delay="1000"
            >
              Our services
            </h1>
            <p
              className="new-serivce-text"
              data-aos="zoom-in"
              data-aos-delay="1500"
              data-aos-easing="ease"
            >
              Our custom services are meticulously tailored to address our
              clients' unique needs, with a primary focus on our cutting-edge
              SaaS product offerings. From streamlining data processing to
              developing innovative applications, including ERP solutions, we
              provide flexible options—whether onshore, offshore, onsite, or a
              blend of these approaches. Leveraging the wealth of collected
              data, ProCohat delivers expert services designed to optimize
              performance and drive your business forward.
            </p>
            <div className="new-angle">
              <img
                src={Triangle}
                alt=""
                data-aos="rotate"
                data-aos-easing="ease"
                data-aos-delay="500"
              />
            </div>
            <Link to="/contact">
              <button
                className="btn new-btn-touch"
                data-aos="fade-up"
                data-aos-delay="2000"
                data-aos-easing="ease"
              >
                Get in touch <i class="bi bi-chevron-right"></i>
              </button>
            </Link>
          </div>
        </div>
      </section>
  )
}

export default HeroSection
