import React from 'react'
import MainServices from '../components/services/MainServices'
import SlideBtn from '../components/slidebtn/SlideBtn'

const NewServices = () => {
  return (
    <>
       <MainServices />
       <SlideBtn />
    </>
  )
}

export default NewServices
