import React, {useEffect} from 'react';
import './style/heroSection.css';
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";

const HeroSection = () => {
      useEffect (()=>{
        Aos.init({
          duration: 1000,
          offset: 100,
        });
      },[]);
  return (
    <>
     <section className='hero-section-new w-full h-[100vh] bg-center bg-cover flex flex-col justify-center items-center'>
        <div className='hero-container-new w-full max-w-[1200px]'>
          <div className="hero-heading-new w-full">
          <h2 data-aos="fade-right" data-aos-delay="500" data-aos-easing="ease" className='text-white text-[48px] font-bold'>
          We are transforming vision</h2>
          <h2 data-aos="fade-right" data-aos-delay="700" data-aos-easing="ease" className='text-white text-[48px] font-bold -mt-2'>into reality through our</h2>
          <h2 data-aos="fade-right" data-aos-delay="900" data-aos-easing="ease" className='text-white text-[48px] font-bold -mt-2'>expertise.</h2>
          <p className='text-[21px] font-normal text-[#FFFFFFBA] mt-3 mr-[31em]' data-aos="fade-up" data-aos-delay="1100" data-aos-easing="ease">We are the single stop for all your digital experiences. Be it Design, Development, SaaS, Event management , we got it all covered.</p>
          </div>
          <div className="hero-button-new w-full mt-7" data-aos="fade-up" data-aos-delay="1500">
           <Link to='/contact'><button className='text-white hover:text-white py-3 pl-8 flex items-center justify-between gap-3 relative'>Know more <FaArrowRight className='bg-white absolute right-[-10px] w-[60px] h-[60px] rounded-[50%] text-[20px] p-4 text-[#6E24B5]'/></button> </Link>
          </div>
        </div>
     </section>
     
    </>
  )
}

export default HeroSection

