import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/infivent.css";
import "../styles/button.css";
import InfiventImg from "../assets/images/infivent-1.png";
// import Lottie from "lottie-react";
// import InfiventAnm from "../assets/lottie/infivent-animation.json";
import MobileImage from "../assets/images/main-infivent.png";
import Aos from "aos";
import "aos/dist/aos.css";
import SlideBtn from "../components/slidebtn/SlideBtn";
import Header from "../components/header/Header";

const Infivent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(()=>{
    Aos.init({
      duration: 2000,
      offset: 100,
    });
  },[])
  return (
    <>
      <Header />
      <section className="infivent-hero">
        <div className="infivent-hero-container">
          <div className="infivent-hero-content">
            <h1 data-aos="fade-right" data-aos-easing="ease">
              Elevate Your Events{" "}
            </h1>
            <h1
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-easing="ease"
            >
              with{" "}
              <span className="color-change" style={{ color: "#BE7EF9" }}>
                infivent
              </span>
            </h1>
            <img
              src={InfiventImg}
              alt="InfiventImage"
              className="infivent-image-tablet-mobile"
            />
            <h3
              data-aos="fade-right"
              data-aos-delay="900"
              data-aos-easing="ease"
            >
              Innovative Event Management and Networking Solutions
            </h3>
            <div className="infivent-button">
              <button
                className="btn btn-infivent"
                style={{ marginTop: "40px", height: "50px", lineHeight: "1" }}
                data-aos="fade-up"
                data-aos-delay="1100"
                data-aos-easing="ease"
              >
                <Link
                  to="https://www.infivent.io/"
                  target="_blank"
                  style={{ fontWeight: "400", textDecoration: "none" }}
                >
                  Check Out Website <i class="bi bi-chevron-right"></i>
                </Link>
              </button>
            </div>
          </div>
          <div className="infivent-hero-image">
            <img src={InfiventImg} alt="InfiventImage" />
          </div>
        </div>
      </section>
      <section className="infivent-main">
        <div className="infivent-main-container">
          <div className="infivent-main-image">
            <div className="step-cont">
            <h1 style={{color : '#fff'}}>Step into a world of seamless connections, unparalleled engagement, and limitless opportunities with infivent !</h1>
            </div>
            <img src={MobileImage} alt="MobileImage" />
            {/* <Lottie animationData={InfiventAnm} /> */}
          </div>
          <div className="color-line"></div>
        </div>
        <div className="infivent-content-container">
          <div className="infivent-content">
            <p data-aos="fade-up" data-aos-easing="ease">
              Infivent, our cutting-edge SaaS product, revolutionizes event
              management. Tailored for B2B expos, it boasts AI-Powered
              Networking, Real-time Analytics, Customizable Event Website &
              Mobile App, and more. Elevate attendee experience, streamline
              exhibitor management, and enhance communication effortlessly.
              Experience the future of event planning with Infivent.
            </p>
            <Link
              to="https://calendly.com/wasudev/infivent-demo?month=2023-09"
              target="_blank"
            >
              <button
                className="btn btn-quote"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="1000"
              >
                Request a Demo
                <i class="bi bi-chevron-right"></i>
              </button>
            </Link>
          </div>
        </div>
      </section>
      <SlideBtn />
    </>
  );
};

export default Infivent;
