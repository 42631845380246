import React from 'react'
import Main from '../components/homepage/Main';
import SlideBtn from '../components/slidebtn/SlideBtn';

const NewHome = () => {
  return (
    <>
       <Main />
       <SlideBtn />
    </>
  )
}

export default NewHome
