import React,{useEffect} from 'react';
import './style/about.css'; 
import Aos from "aos";
import "aos/dist/aos.css";
import AboutImage from '../../../assets/images/about-piller.png';

const About = () => {
    useEffect (()=>{
        Aos.init({
          duration: 1000,
          offset: 100,
        });
      },[]);
  return (
    <>
      <section className='about-new-section py-10 w-full flex justify-center items-center'>
        <div className="container about-new-container max-w-[1200px]">
          <div className='about-new-content flex flex-row w-full justify-center items-center'>
            <div className="about-new-image w-[50%] -mr-32">
              <img src={AboutImage} alt='Image-Piller' className='w-full'/>
            </div>
            <div className="about-new-text w-[50%]">
                <h2 className='text-white text-[76.93px] -ml-64' data-aos="fade-left" data-aos-delay="500" data-aos-easing="ease">Who We Are?</h2>
                <p className='text-[#BCBCBC] text-[21px] font-light -ml-10 mt-5' data-aos="fade-up" data-aos-delay="700" data-aos-easing="ease">Experience that delivers Rapid Results. Combining human-led and tech-powered innovations and experience to deliver faster, more sustainable, and meaningful outcomes. We offer mission-critical IT solutions to transform global businesses. We aim to deliver excellence for our customers and support communities around the world.</p>
            </div>
          </div>
        </div>  
      </section>
    </>
  )
}

export default About;
