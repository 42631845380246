import React, {useEffect} from 'react';
import Aos from "aos";
import "aos/dist/aos.css";
import './style/aboutinfo.css';
import Triangle from '../../../assets/images/angle-2.svg';

const AboutInfo = () => {
    useEffect(()=>{

        Aos.init({
          duration: 2000,
          offset: 100,
        });
      },[]);
  return (
    <>
       <section className="aboutinfo-main">
        <div className="aboutinfo-main-container">
          <div className="svgAngle">
            <img
              src={Triangle}
              alt="Tringle"
              data-aos="fade-right"
              data-aos-easing="ease"
            />
          </div>
          <div className="aboutinfo-main-content">
            <div className="aboutinfo-main-content-text">
              <h1
                data-aos="fade-left"
                data-aos-easing="ease"
                data-aos-delay="1000"
              >
                About Us
              </h1>
              <p
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="1200"
              >
                With a promise to deliver tech that helps our clients to drive
                business and growth, we at ProCohat Technologies are your
                trusted Technological Solutions and Products Partners. By
                combining our deep technical expertise and industry experience,
                we strive to offer digital solutions and tailor made SAAS
                products for businesses. We believe in equipping our clients
                with the answers for what’s next in Tech, beforehand. Our proven
                solutions offer a unique competitive advantage for our clients
                and empower them to envision beyond.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutInfo
