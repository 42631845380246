import React from "react";
import "./styles/slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { EffectCoverflow } from "swiper/modules";
import SwiperCore from "swiper";
import Php from "../../assets/images/php.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NodeJs from "../../assets/images/Node-js.png";
import ExpressJs from "../../assets/images/express-js.png";
import GoogleCloud from "../../assets/images/GoogleCloud.png";
import Aws from "../../assets/images/Aws.png";
import Azure from "../../assets/images/Azure.png";
import AliBabaCloud from "../../assets/images/alibaba.png";
import SpeedCloud from "../../assets/images/speedcloud.png";
import HtmlCssJs from  "../../assets/images/html-css-js.png";
import Flutter from "../../assets/images/flutter.png";
import ReactJs from "../../assets/images/React.png";
import AngularJs from  "../../assets/images/AngularJs.png";
import Ionic from "../../assets/images/ionic.png";
import Bootstrap from "../../assets/images/Bootstrap.png";
import WordPress from "../../assets/images/WordPress.png";
import Shopify from "../../assets/images/Shopify.png";
import Magneto from "../../assets/images/magento.png";
import Drupal from "../../assets/images/Drupal.png";
import Woocommerce from "../../assets/images/Woocommerce.png";
import Mysql from "../../assets/images/Mysql.png";
import MangoDb from "../../assets/images/MangoDb.png";
import PostgreSql from "../../assets/images/postgresql.png"

SwiperCore.use([EffectCoverflow]);

const SliderShow = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };
  return (
    <div className="slider-section-of-languages">
      <div className="slider-swiper-main-container">
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          spaceBetween={0}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
            slideShadows: true,
          }}
          initialSlide={2}
          className="swiper_container"
        >
          <SwiperSlide>
            <div className="slider-content-container">
              <div className="slider-image">
                <Slider {...settings}>
                  <div>
                    <img src={Php} alt="Php" />
                  </div>
                  <div>
                    <img src={NodeJs} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={ExpressJs} alt="ExpressJs" />
                  </div>
                </Slider>
              </div>
              <h3 className="title-of-slide">Backend</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-content-container">
              <div className="slider-image">
                <Slider {...settings}>
                  <div>
                    <img src={GoogleCloud} alt="GoogleCloud" />
                  </div>
                  <div>
                    <img src={Aws} alt="Aws" />
                  </div>
                  <div>
                    <img src={Azure} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={AliBabaCloud} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={SpeedCloud} alt="NodeJs" />
                  </div>
                </Slider>
              </div>
              <h3 className="title-of-slide">Cloud</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-content-container">
              <div className="slider-image">
              <Slider {...settings}>
                  <div>
                    <img src={HtmlCssJs} alt="GoogleCloud" />
                  </div>
                  <div>
                    <img src={Flutter} alt="Aws" />
                  </div>
                  <div>
                    <img src={ReactJs} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={AngularJs} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={Ionic} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={Bootstrap} alt="NodeJs" />
                  </div>
                </Slider>
              </div>
              <h3 className="title-of-slide">Frontend</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-content-container">
              <div className="slider-image">
              <Slider {...settings}>
                  <div>
                    <img src={WordPress} alt="GoogleCloud" />
                  </div>
                  <div>
                    <img src={Shopify} alt="Aws" />
                  </div>
                  <div>
                    <img src={Magneto} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={Drupal} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={Woocommerce} alt="NodeJs" />
                  </div>
                </Slider>
              </div>
              <h3 className="title-of-slide">CMS</h3>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slider-content-container">
              <div className="slider-image">
              <Slider {...settings}>
                  <div>
                    <img src={Mysql} alt="Php" />
                  </div>
                  <div>
                    <img src={MangoDb} alt="NodeJs" />
                  </div>
                  <div>
                    <img src={PostgreSql} alt="ExpressJs" />
                  </div>
                </Slider>
              </div>
              <h3 className="title-of-slide">Database</h3>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};
export default SliderShow;
